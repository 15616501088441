import { useEffect } from 'react';
import { useParams } from 'react-router'
import style from './style.module.css'

const Pdf = () => {
  const p = useParams()

  


  return (
    <div className={style.container}  >
      <embed
        className={style.content_viewport}
     style={{height:'82vh'}}
      
        src={`https://drive.google.com/file/d/${p.name}/preview`}
      ></embed>
    </div>

    /* <div ref={divRef}>
        <div className="flex justify-center">
          {pageNumber} / {numPages}{" "}
        </div>
      
        <div className="flex justify-center">
            {pageNumber > 1 && (
              <button onClick={handlePreviousPage}> Previous</button>
            )}
            {pageNumber < numPages && (
              <button onClick={handleNextPage}> Next</button>
            )}
          </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Document
            className={"PDFDocument"}
            file={`/certificate/${name}.pdf`}
            onLoadError={() => alert("error")}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              className={"PDFPage PDFPageOne"}
              pageNumber={pageNumber}
              // width={divRef.current?.clientWidth * 0.66}
            />
          </Document>
        </div>
        <div>
         
        </div>

    </div>
     */
  )
}

export default Pdf
{
  /* <div id="">
{name && (
  <Document file={`/certificate/${name}.pdf`}   className={"PDFDocument"} onLoadSuccess={onDocumentLoadSuccess}>
    {Array.from(new Array(numPages), (el, index) => (
      <Page 
      className={"PDFPage PDFPageOne"}
      key={`page_${index + 1}`} pageNumber={index + 1} />
    ))}
  </Document>
)}
</div> */
}
