import React from "react";
import { AiOutlineCopyrightCircle } from "react-icons/ai";

const Footer = () => {
  return (
    <div
      style={{ backgroundColor: "rgb(5 177 255)" }}
      className="text-white w-[100%] md:text-base sm:text-base h-[5vh] fixed bottom-0  bg-red-400 flex justify-center flex-wrap items-center">
      <span className=" md:text-lg sm:text-sm font-medium mx-1"> Building Experts</span>
      <span className="md:text-lg sm:text-sm">
        <AiOutlineCopyrightCircle />
      </span>{" "}
      - 2022, All rights are reserved
    </div>
  );
};

export default Footer;
