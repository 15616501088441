import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import Loader from "../../components/loading";

const ProductDetailsPage = () => {
  const [productDetailse, setProductDetailse] = useState();
  const [loadingProductDetailse, setLoadingProductDetailse] = useState(false);

  const { id } = useParams();

  const getCategory = async () => {
    setLoadingProductDetailse(true);
    await axios({
      method: "get",
      url: `https://api.buildings-experts.com/api/v1/products/${id}`,
    })
      .then((data) => {
        setProductDetailse(data?.data);
      })
      .finally((_) => {
        setLoadingProductDetailse(false);
      });
  };

  useEffect(() => {
    getCategory();
  }, [id]);
  return (
    <>
      {loadingProductDetailse ? (
        <div className=" h-screen  bg-slate-300 w-screen">
          <div className="flex justify-center items-center h-[70vh]">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-cente flex-col items-center my-10">
            <div className="grid w-[80vw] lg:grid-cols-2 sm:grid-cols-1 gap-8 mx-8">
              {/* fist colomn */}
              <div className="flex flex-col items-center">
                {productDetailse?.coverImage && (
                  <img
                    className="max-h-[20vh] rounded-lg  object-cover mb-3"
                    src={productDetailse?.coverImage?.url}
                    alt="aa"
                  />
                )}
                <img
                  className="object-fill h-[80vh] rounded-lg xs:w-full sm:w-[30vw]"
                  src={productDetailse?.mainImage?.url}
                  alt="sss"
                />
              </div>

              {/* secound Coloum */}
              <div className="h-auto  overflow-y-auto">
                <div className="my-3 text-3xl text-black font-semibold">
                  {productDetailse?.title}
                </div>
                <div className="text-xl text-gray-500">
                  {productDetailse?.subtitle}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetailse?.description,
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col w-[80vw]  mx-16 ">
              <div className="mb-3 hover:text-red-400">
                {productDetailse?.link && (
                  <span className="text-black font-bold text-xl">Link : </span>
                )}
                <a
                  className="hover:underline mb-3 transition duration-200"
                  _blank={true}
                  href={productDetailse?.link}
                >
                  {productDetailse?.link}
                </a>
              </div>
              <div
                style={{ listStyle: "desc" }}
                className="flex flex-wrap"
                dangerouslySetInnerHTML={{ __html: productDetailse?.content }}
              />
              <div className="flex fle-col">
                {productDetailse?.images?.length > 0 && (
                  <>
                    <div className="w-screen mt-10 rounded-md">
                      <hr className="h-6" />
                      <Gallery>
                        <div className="flex  gap-6 h-35 justify-end flex-wrap">
                          {productDetailse?.images?.map((img, index) => {
                            return (
                              <Item
                                original={img?.url}
                                thumbnail={img?.url}
                                width="500"
                                height="500"
                              >
                                {({ ref, open }) => (
                                  <img
                                    alt="Imagee"
                                    ref={ref}
                                    style={{
                                      borderRadius: "10px",
                                      border: "1px",
                                      width: "6rem",
                                      height: "7rem",
                                    }}
                                    onClick={open}
                                    src={img?.url}
                                  />
                                )}
                              </Item>
                            );
                          })}
                        </div>
                      </Gallery>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDetailsPage;
