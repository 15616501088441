import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  DocumentIcon,
  BuildingLibraryIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import brand from '../../../assets/images/brand.jpg'
import { Fragment, useEffect, useState } from 'react'
import axios from 'axios'

const resources = [
  {
    name: 'ISO 9001',
    description:
      'Get all of your questions answered in our forums or contact support.',
    href: 'ISO-9001',
    icon: DocumentIcon,
  },
  {
    name: 'ISO 14001',
    description:
      'Get all of your questions answered in our forums or contact support.',
    href: 'ISO-14001',
    icon: DocumentIcon,
  },
  {
    name: 'ISO 45001',
    description:
      'Get all of your questions answered in our forums or contact support.',
    href: 'ISO-45001',
    icon: DocumentIcon,
  },
]
const companys = [
  {
    name: 'Brochure Brands',
    description:
      'Get all of your questions answered in our forums or contact support.',
    href: '1-LUcGJt0vOUDU0MjxwD05-b4sQkhZyly',
    icon: BuildingLibraryIcon,
  },

  {
    name: 'Company Profile',

    description:
      'Get all of your questions answered in our forums or contact support.',
    href: '1pghEUh9jIqRwhN8javXbbftZVwYhF6sT',
    icon: Square3Stack3DIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = () => {
  const [categories, setCategories] = useState([])
  const [loadingCategories, setLoadingCategories] = useState(false)

  const getCategories = async () => {
    setLoadingCategories(true)
    await axios({
      method: 'post',
      url: 'https://api.buildings-experts.com/api/v1/product-categories/index',
      headers: {},
      data: {
        staticFilters: [
          {
            name: 'parentId',
            opretion: '=',
            value: null,
          },
        ],
      },
    })
      .then((data) => {
        setCategories(data.data?.data)
      })
      .finally((_) => {
        setLoadingCategories(false)
      })
  }

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <Popover className="relative bg-white ">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-auto">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Building Experts</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={brand}
                alt="Building Experts"
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-14 md:flex">
            <Link
              to="/"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Home
            </Link>
            <Link
              to="/about-us"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
            
              About Us
            </Link>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900'
                    )}
                  >
                    <span>Categories</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-40 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className=" grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {categories
                ?.filter(item => item.name !== 'Highlights')
                .map(item => (
                  <Link
                              key={item.name}
                              to={`/category/${item.id}`}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                            >
                              {/* <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden="true" /> */}
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                {/* <p className="mt-1 text-sm text-gray-500 truncate w-fit">
                                  {item?.description}
                                </p> */}
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white  text-base font-medium hover:text-gray-900'
                    )}
                  >
                    <span>Company Documents</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-40 -ml-4 mt-3 w-[20vw] max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className=" grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {companys.map((item) => (
                            <Link
                              key={item?.name}
                              to={`p/${item.href}`}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                            >
                              <item.icon
                                className="h-6 w-6 flex-shrink-0"
                                style={{
                                  color: 'rgb(1,150,300)',
                                  fontWeight: 'bold',
                                }}
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item?.name}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900'
                    )}
                  >
                    <span>ISO Certificates</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3  w-[15vw] max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg">
                        <div className=" grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <Link
                              key={item?.name}
                              to={`pdf/${item?.href}`}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                            >
                              <item.icon
                                className="h-6 w-6 flex-shrink-0"
                                style={{
                                  color: 'rgb(1,150,300)',
                                  fontWeight: 'bold',
                                }}
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item?.name}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
              
            </Popover>
            <Link
             to="/Highlights"
             className="text-base font-medium text-gray-500 hover:text-gray-900">
              Highlights
            </Link>
      
            <Link
              to="/contact-us"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Contact Us
            </Link>      
           
            </Popover.Group>
        </div>
      </div>       
            

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-50  inset-x-0 top-0 bg-white origin-top-right transform p-[.5px] transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5  w-[100vw] pb-[0.5px]">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={brand} alt="Your Company" />
                </div>
                <div className="-m-2">
                  <Popover.Button className="inline-flex items-center  justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-00 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-4 w-screen  bg-blue-300">
                <nav
                  style={{ backgroundColor: 'rgb(2,149,218)' }}
                  className="grid gap-y-5 py-4 absolute inset-x-0 z-50 w-screen ring-1   transform p-[.5px] transition "
                >
                  <Link
                    to="/"
                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                  >
                    <div className="ml-4">
                      <p className="text-base font-medium text-white">Home</p>
                    </div>
                  </Link>

                  <Link
                    to="/about-us"
                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                  >
                    <div className="ml-4">
                      <p className="text-base font-medium  text-white">
                        About Us
                      </p>
                    </div>
                  </Link>
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-white' : 'text-white',
                            'group inline-flex items-center m-3 text-base font-medium'
                          )}
                        >
                          <span>Categories</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-gray-400',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-40 -ml-2 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                            <div className="overflow-hidden rounded-lg bg-white  ring-opacity-5">
                              <div className="grid gap-6 py-6 sm:gap-8 sm:p-2">
                                {categories?.map((item) => (
                                  <Link
                                    key={item.name}
                                    to={`/category/${item.id}`}
                                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                                  >
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">
                                        {item?.name}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-white' : 'text-white',
                            'group inline-flex items-center m-3 text-base  font-medium'
                          )}
                        >
                          <span>Company Documents</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-white',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-50 -ml-2 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                            <div className="overflow-hidden rounded-lg bg-white  ring-opacity-5">
                              <div className="grid gap-6 py-6 sm:gap-8 sm:p-2">
                                <Link
                                  to="p/1-LUcGJt0vOUDU0MjxwD05-b4sQkhZyly"
                                  className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                                >
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">
                                      Brochure Brands
                                    </p>
                                  </div>
                                </Link>
                                <Link
                                  to="p/1pghEUh9jIqRwhN8javXbbftZVwYhF6sT"
                                  className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                                >
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">
                                      Company Profile
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-white' : 'text-white',
                            'group inline-flex items-center m-3 text-base  font-medium'
                          )}
                        >
                          <span>ISO Cartficate</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-white',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-50 -ml-2 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                            <div className="overflow-hidden rounded-lg bg-white  ring-opacity-5">
                              <div className="grid gap-6 py-6 sm:gap-8 sm:p-2">
                                {resources?.map((item) => (
                                  <Link
                                    key={item.name}
                                    to={`pdf/${item?.href}`}
                                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                                  >
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">
                                        {item?.name}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                   
              
                  <Link
                    to="/Highlights"
                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                  >
                    <div className="ml-4">
                      <p className="text-base font-medium  text-white">
                      Highlights
                      </p>
                    </div>
                  </Link>
                  <Link
                    to="/contact-us"
                    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-400"
                  >
                    <div className="ml-4">
                      <p className="text-base font-medium  text-white">
                        Contact Us
                      </p>
                    </div>
                  </Link>
                </nav>
              </div>
              </div>
            </div>
              
        </Popover.Panel>
        
      </Transition>
    </Popover>
    
    
    
  )
}



export default Navbar
