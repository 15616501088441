import React from "react";
import styles from "./style.module.css";
import { Bounce, Zoom } from "react-reveal";

const AboutUsPage = () => {
  return (
    <>
      <div className="flex justify-center items-center flex-col my-6">
        <Zoom top duration={1500}>
          <div className={`text-5xl ${styles.title}`}>About Us</div>
          <div
            className={`${styles.first} rounded-md w-screen  px-56 mt-2 text-white flex justify-center items-center flex-col`}
          >
            <div className="w-[80vw] text-center my-6 font-serif">
              Building Experts Company is a General Engineering and Contracting
              company that was founded in 2012. The current owners continue the
              construction and engineering in family tradition while maintaining
              its outstanding reputation with an uncompromising commitment to
              quality and safety. With a strong reputation as an industry leader
              in Design-Build construction, Building Experts provides excellence
              in a full spectrum of pre-construction, projects’ management,
              design development, interior design and management, inspection,
              quality control services, construction works, and full turnkey
              solutions.
            </div>
            <div className="w-[80vw] text-center my-6  font-serif">
              The Company has retained an enduring core of executive management,
              senior managers, and value-driven professionals for over four
              decades with its joint venture with the architectural and
              engineering firm KFA, that was founded in 1982.
            </div>
            <div className="w-[80vw] text-center my-6  font-serif">
              Over thirty (30) employees have worked at the company for over
              eight years and 40+ employees have worked at Building Experts for
              more than five years. This type of mutual commitment is virtually
              unparalleled in the industry.
            </div>
            <div className="w-[80vw] text-center my-6  font-serif">
              Building Experts continues to deliver the best value in building
              services by placing experienced construction professionals on
              every project undertaken.
            </div>
            <div className="w-[80vw] text-center my-6  font-serif">
              Building Experts is the exclusive agent for many buildings
              materials brand and companies in Iraq, such as KÖSTER
              waterproofing materials, BAUER waterproofing and construction
              materials, GRADA Construction chemicals, CORROSOL UV Cured GRP,
              REFLECTIV Window films, BUILDING EXPERTS BALLISTIC solutions, Full
              Form GRC and GRP cladding systems, PANETTI Sound insulation
              system.
            </div>
            <div className="w-[80vw] text-center my-6  font-serif">
              Building Experts Head Quarter is in Erbil with a branch in Baghdad
              to cover the Iraqi territories.
            </div>
          </div>
        </Zoom>
      </div>
      <div
        className={`${styles.first}  flex justify-around py-3 md:flex-row xs:flex-col items-center min-h-[30vh] px-56 shadow-md shadow-blue-300`}
      >
        <Bounce duration={3000} top>
          <div className="text-white text-4xl font-medium text-center drop-shadow-lg">
            Our Vision
          </div>
        </Bounce>
        <div>
          <Bounce right duration={3000}>
            <div className="w-[50vw] text-lg text-center text-white">
              For our devotion, competence, and proven leadership, BE will
              strive to maintain a highly reputable organization in the
              engineering ﬁeld.
            </div>
          </Bounce>
        </div>
      </div>
      <div
        className={`${styles.secound}  flex justify-around py-3 md:flex-row xs:flex-col items-center  min-h-[30vh] my-5 px-56 shadow-md shadow-blue-300`}
      >
        <Bounce left duration={3000}>
          <div className="w-[50vw] text-white text-center">
          To provide quality workmanship and customer service and maintain the
            highest level of professionalism, 
            <br />
         honesty and fairness in our
            relationships with our customers, employees and vendors. To grow by
            continually providing useful and significant products, services, and
            solutions to markets we already serve – and to expand into new areas
            that build on our competencies and customer interests.
          </div>
        </Bounce>
        <Bounce right duration={3000}>
          <div className="text-white text-4xl font-medium text-center">
            Our Mission
          </div>
        </Bounce>
      </div>
      <Zoom duration={3000}>
        <div className={`text-center text-4xl , ${styles.title}`}>
          Our History{" "}
        </div>
      </Zoom>

      <div className="flex justify-center items-center mb-20 mt-10 w-[100%]  md:flex-row xs:flex-col gap-6">
        <section
          class=" h-96 w-80 -rotate-3 hover:scale-110 transform s rounded-2xl bg-gray-200 p-2 duration-300 hover:rotate-0"
          style={{ background: "rgba(0,173,238, 0.4)" }}
        >
          <div class="flex justify-end">
            <div class="h-4 w-4 rounded-full bg-blue-500"></div>
          </div>
          <header class="text-center font-extrabold text-3xl text-gray-600">
            2012
          </header>

          <div>
            <p class="text-center font-medium text-white pt-6 text-lg">
              Joint venture agreement between SB (Iraq) and Building Experts
              (Syria):
            </p>
            <p class="text-center mt-8 font-medium ">
              To establish an engineering ﬁrm in Iraq for engineering
              consultancy and projects’ management.
            </p>
          </div>
        </section>
        <section
          class=" h-96 w-80 -rotate-3 transform s rounded-2xl bg-gray-200 p-2 hover:scale-110 duration-300 hover:rotate-0"
          style={{ background: "rgba(0,173,238, 0.4)" }}
        >
          <div class="flex justify-end">
            <div class="h-4 w-4 rounded-full bg-blue-500"></div>
          </div>
          <header class="text-center font-extrabold text-3xl text-gray-600">
            2016
          </header>

          <div>
            <p class="text-center font-medium text-white pt-6 text-lg">
              Establishing Building Experts Ltd
            </p>
            <p class="text-center mt-8">
              The main target is to provide leading modalities and services such
              as: Design and build, Project management and cost management,
              smart buildings solutions
            </p>
          </div>
        </section>
        <section
          class=" h-96 w-80 -rotate-3 transform s  hover:scale-110 rounded-2xl bg-gray-200 p-2 duration-300 hover:rotate-0"
          style={{ background: "rgba(0,173,238, 0.4)" }}
        >
          <div class="flex justify-end">
            <div class="h-4 w-4 rounded-full bg-blue-500"></div>
          </div>
          <header class="text-center font-extrabold text-3xl text-gray-600">
            2017
          </header>

          <div>
            <p class="text-center font-medium text-white pt-6 text-lg">
              Building Experts Ltd. Expanded its work to the commercial ﬁeld
            </p>
            <p class="text-center mt-8">
              By representing construction materials, ﬁnishing materials and
              furniture’s companies in Iraq such as KÖSTER, BAUER, REFLECTIV,
              GRADA, MODENESE, PRIMA ITALIANA, ARCAHORN.
            </p>
          </div>
        </section>
        <section
          class=" h-96 w-80 -rotate-3 transform s hover:scale-110 rounded-2xl bg-gray-200 p-2 duration-300 hover:rotate-0"
          style={{ background: "rgba(0,173,238, 0.4)" }}
        >
          <div class="flex justify-end">
            <div class="h-4 w-4 rounded-full bg-blue-500"></div>
          </div>
          <header class="text-center font-extrabold text-3xl text-gray-600">
            2019
          </header>

          <div>
            <p class="text-center font-medium text-white pt-6 text-lg">
              Establishing the Ballistics division
            </p>
            <p class="text-center mt-8">
              BE Ballistic to is specialized in the ballistic solutions, such
              as: ballistic rooms, safe rooms, ballistic windows.
            </p>
          </div>
        </section>
      </div>
      <Zoom duration={3000}>
        <div className={`text-center text-4xl , ${styles.title}`}>
          Our services
        </div>
      </Zoom>
      <div className="flex justify-center items-center text-lg font-medium">
        <Zoom duration={3000}>
          <span className=" w-[50vw] text-center my-3">
            The main target is to provide leading modalities and services such
            as: Design and build, Project management and cost management, smart
            buildings solutions.
          </span>
        </Zoom>
      </div>
      <br />
      <br />
      <br />
      <Zoom duration={3000}>
        <div className={`text-center text-4xl , ${styles.title}`}>
          What We Do
        </div>
      </Zoom>
      <div className="flex justify-center min-h-fit items-center mb-20 mt-10  mx-20  md:flex-row flex-wrap xs:flex-col gap-6">
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Site Planning
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">Banks</span>
            </div>
          </div>
        </Bounce>
        <Bounce right duration={6000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Educational Facilities
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Private Villas, Villa Complexes
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Hotel & Hotel Apartments
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce right duration={6000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Mixed Use Building
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Religious Facilities
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Offices Buildings
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce right duration={6000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Residential Buildings
              </span>
            </div>
          </div>
        </Bounce>
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">Offices</span>
            </div>
          </div>
        </Bounce>
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">Stands</span>
            </div>
          </div>
        </Bounce>
        <Bounce right duration={6000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Retail Projects
              </span>
            </div>
          </div>
        </Bounce>
      </div>
      <div className="flex justify-center items-center mb-20 mt-10  mx-20  md:flex-row xs:flex-col gap-6">
        <Bounce left duration={4000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Health, Safety and Environment
              </span>
            </div>
            <div className="mt-2">
              <p className="mt-2 text-gray-600">
                Buildings Experts’ priorities include ensuring that its
                operations are safe for its employees and the communities in
                which it operates, and that all the Company’s equipment and
                facilities are properly maintained. All employees shall adopt
                safety rules and regulations.
              </p>
            </div>
          </div>
        </Bounce>
        <Bounce right duration={6000}>
          <div className="max-w-4xl px-10 my-4 py-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-between items-center">
              <span className="font-medium text-black text-2xl">
                Discrimination and Harassment
              </span>
            </div>
            <div className="mt-2">
              <p className="mt-2 text-gray-600">
                Preserving the dignity and integrity of employees and partners
                in accordance with the laws where Buildings Experts operates,
                all employees are offered equal opportunities with regards to
                hiring, compensation, training, development, and promotion. No
                one should be discriminated against on grounds of gender,
                marital status, age, religion, race, physical ability, political
                preference, social class, or any other condition.
              </p>
            </div>
          </div>
        </Bounce>
      </div>
    </>
  );
};

export default AboutUsPage;
