import { Route, Routes,Navigate } from "react-router";
import HomePage from "./pages/home";
import "./App.css";
import CategoryDetailsPage from "./pages/category-details";
import NotFoundPage from "./pages/404";
import ProductDetailsPage from "./pages/product-details";
import ContactUsPage from "./pages/contact-us";
import PdfReader from "./pages/pdf-reader";
import Navbar1 from "./components/layout/navbar";
import { Toaster } from "react-hot-toast";
import Footer from "./components/footer";
import AboutUsPage from "./pages/about-us";
import Pdf from "./pages/pdf";
import NewsPage from "./pages/news";


function App() {
  return (
    <div>
      <Toaster />
      <Navbar1 />
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/category/:id" element={<CategoryDetailsPage />} exact />
        <Route path="/product/:id" element={<ProductDetailsPage />} exact />
        <Route path="/contact-us" element={<ContactUsPage />} exact />
        <Route path="/about-us" element={<AboutUsPage />} exact />
        <Route path="/pdf/:name" element={<PdfReader />} exact />
        <Route path="/pdf" element={<PdfReader />} exact />
        <Route path="/p/:name" element={<Pdf />} exact />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/Highlights" element={<Navigate to="/category/84" />} exact />
        <Route path="/Highlights/category/:id" element={<CategoryDetailsPage />} exact />
        <Route path="/Highlights/product/:id" element={<ProductDetailsPage />} exact />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
