import axios from "axios";
import React, { useState } from "react";
import { Zoom, Rotate } from "react-reveal";

import toast from "react-hot-toast";
import Fade from "react-reveal";

const ContactUsPage = () => {
  const [message, setMessage] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
  });
  const handelSubmit = async (event, data) => {
    event.preventDefault();
    await axios({
      method: "post",
      data: {
        name: message.name,
        email: message.email,
        subject: message.subject,
        message: message.message,
      },
      url: `https://api.buildings-experts.com/api/v1/inboxes`,
    })
      .then((res) => {
        notify();
      })
      .catch((err) => {
        // notifyError()
      })
      .finally((_) => {});
  };

  const notify = () => toast.success("Sended Successfully", {});

  return (
    <>
      <div className="container my-14 px-6 mx-auto ">
        <section className="mb-32 text-gray-800">
          <div
            className="relative overflow-hidden bg-no-repeat bg-cover"
            style={{
              backgroundPosition: "50%",
              backgroundImage:
                "url(https://mdbootstrap.com/img/new/textures/full/171.jpg)",
              height: "300px",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          ></div>
          <div className="container text-gray-800 px-4 md:px-12 ">
            <div
              className="block rounded-lg shadow-lg py-10 md:py-12 px-4 md:px-6"
              style={{
                marginTop: "-100px",
                background: " hsla(0, 0%, 100%, 0.8)",
                backdropFilter: "blur(30px)",
              }}
            >
              <div className="max-w-[700px] mx-auto">
                <form onSubmit={(e) => handelSubmit(e, message)}>
                  <Fade left duration={1000}>
                    <div className="form-group mb-6">
                      <input
                        required
                        type="text"
                        onChange={(e) => {
                          console.log(e.target.value);
                          setMessage({
                            ...message,
                            name: e.target.value,
                          });
                        }}
                        className="form-control block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-cyan-300 focus:outline-none"
                        id="exampleInput7"
                        placeholder="Name"
                      />
                    </div>
                  </Fade>
                  <Fade right duration={1000}>
                    <div className="form-group mb-6">
                      <input
                        required
                        type="email"
                        onChange={(e) => {
                          setMessage({
                            ...message,
                            email: e.target.value,
                          });
                        }}
                        className="form-control block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-cyan-300 focus:outline-none"
                        id="exampleInput8"
                        placeholder="Email address"
                      />
                    </div>
                  </Fade>
                  <Fade left duration={1000}>
                    <div className="form-group mb-6">
                      <input
                        required
                        onChange={(e) => {
                          setMessage({
                            ...message,
                            subject: e.target.value,
                          });
                        }}
                        type="text"
                        className="form-control block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-cyan-300 focus:outline-none"
                        id="exampleInput7"
                        placeholder="phone Number"
                      />
                    </div>
                  </Fade>
                  <Fade right duration={1000}>
                    <div className="form-group mb-6">
                      <textarea
                        onChange={(e) => {
                          setMessage({
                            ...message,
                            message: e.target.value,
                          });
                        }}
                        className="
                                form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-cyan-300 focus:outline-none
                              "
                        id="exampleFormControlTextarea13"
                        rows="3"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </Fade>

                  <button
                    type="submit"
                    style={{ backgroundColor: "rgb(1,150,300)" }}
                    className="
                        w-full
                        px-6
                        py-2.5
                        text-white
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                        transition
                        duration-150
                        ease-in-out"
                  >
                    Send
                  </button>
                </form>
              </div>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-x-6 my-12">
                <Zoom left cascade duration={1000}>
                  <div className="mb-12 lg:mb-0 text-center mx-auto hover:scale-125 ease-in-out duration-500 transition-all">
                  <svg
                      style={{ color: "rgb(1,150,300)" }}
                      className="w-8 h-8 mb-6 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      ></path>
                    </svg>
                    <h6 className="font-bold text-lg">Syria</h6>
                    <div>
                      <a class="info" href="tel:+963112125759">
                        +963112125759
                      </a>
                    </div>
                    <div>
                      <a class="info" href="tel:+963112125167">
                        +963112125167
                      </a>
                    </div>
                    <div>
                      <a href="mailto:sales@buildings-experts.com">
                        sales@buildings-experts.com
                      </a>
                    </div>
                  </div>
                </Zoom>

                <Rotate top left cascade duration={1000}>
                  <div className="mb-12 lg:mb-0 text-center mx-auto hover:scale-125 ease-in-out duration-500 transition-all">
                  <svg
                      style={{ color: "rgb(1,150,300)" }}
                      className="w-8 h-8 mb-6 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      ></path>
                    </svg>
                    <h6 className="font-bold text-lg">IRAQ</h6>
                    <div>
                      <a
                        className="info"
                        href="https://g.page/building-experts-erbil?share"
                      >
                        Erbil, Gulan str,Dream City, Villa 1175
                      </a>
                      <div>
                        <a class="info" href="tel:+9647508188196">
                          +9647508188196
                        </a>
                      </div>
                      <div>
                        <a class="info" href="tel:+9647504459912">
                          +9647504459912
                        </a>
                      </div>
                      <div>
                        <a href="mailto:sales@buildings-experts.com">
                          sales@buildings-experts.com
                        </a>
                      </div>
                    </div>
                  </div>
                </Rotate>
                <Rotate top right cascade duration={1000}>
                  <div className="mb-12 lg:mb-0 text-center mx-auto hover:scale-125 ease-in-out duration-500 transition-all">
                  <svg
                      style={{ color: "rgb(1,150,300)" }}
                      className="w-8 h-8 mb-6 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      ></path>
                    </svg>
                    <h6 className="font-bold text-lg">Germany</h6>
                    <div>berlin-eyke von repkow</div>
                    <div>
                      <a class="info" href="tel:+491-7625377084">
                        +491-7625377084
                      </a>
                    </div>
                    <div>
                      <a href="mailto:info@kfa_design.com">
                        sales@buildings-experts.com
                      </a>
                    </div>
                  </div>
                </Rotate>

                <Zoom right cascade duration={1000}>
                  <div className="mb-12 lg:mb-0 text-center mx-auto hover:scale-125 ease-in-out duration-500 transition-all">
                  <svg
                      style={{ color: "rgb(1,150,300)" }}
                      className="w-8 h-8 mb-6 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      ></path>
                    </svg>
                    <h6 className="font-bold text-lg">UAE</h6>
                    <div>TAYMAA FZE</div>
                    <a class="info" href="tel:+971567183747">
                      +971567183747
                    </a>
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUsPage;
