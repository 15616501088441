import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../components/loading";
import style from "./style.module.css";
import Bounce from "react-reveal/Bounce";
import Footer from "../../components/footer";

const CategoryDetailsPage = () => {
  const [categoryDetailse, setCategoryDetailse] = useState();
  const [loadingCategoryDetailse, setLoadingCategoryDetailse] = useState(false);
  const { id } = useParams();

  const getCategory = async () => {
    setLoadingCategoryDetailse(true);
    await axios({
      method: "get",
      url: `https://api.buildings-experts.com/api/v1/product-categories/${id}`,
    })
      .then((data) => {
        console.log(data?.data);
        setCategoryDetailse(data?.data);
      })
      .finally((_) => {
        setLoadingCategoryDetailse(false);
      });
  };

  useEffect(() => {
    getCategory();
    return () => {
      setLoadingCategoryDetailse(false);
    };
  }, [id]);

  return (
    <div className="w-screen h-screen " >
      {loadingCategoryDetailse ? (
        <div className=" h-[87vh] w-scree">
          <div className="flex justify-center items-center h-[70vh]">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <div
            className="w-[100%]  min-h-[20vh] mb-10 "
            style={{ backgroundColor: "rgb(0,173,238)" }}
          >
            <div className="mx-[20vw] pt-[5vh] selection:pb-5 md:text-4xl text-white">
              {categoryDetailse?.description ? (
                <Bounce top>{categoryDetailse?.name}</Bounce>
              ) : (
                <Bounce left>{categoryDetailse?.name}</Bounce>
              )}
            </div>

            <div className="mx-[20vw] pt-[1px] pb-5 text-white">
              <Bounce left>
                <div
                  dangerouslySetInnerHTML={{
                    __html: categoryDetailse?.description,
                  }}
                />
              </Bounce>
            </div>
          </div>

          <div className="flex justify-center md:flex-row  min-h-[87vh] mx-4 mb-10 flex-wrap xs:flex-col gap-4 ">
            {categoryDetailse?.subcategories &&
              categoryDetailse?.subcategories?.map((category) => {
                return (
                  <a href={`/category/${category.id}`}>
                    <div
                      className="relative md:w-[16vw] md:h-[16vw] xs:w-[30v] xs:h-[30vh] group overflow-hidden rounded-lg
                        hover:scale-110 transition duration-300"
                      style={{ background: "rgba(0,173,238, 0.05)" }}
                    >
                      <img
                        src={category?.image?.url}
                        className="absolute h-full w-full object-contain"
                        alt={category?.name}
                      />
                      <div
                        className="absolute h-full w-full invisible group-hover:visible flex justify-center items-center transition"
                        style={{ background: "rgba(0,173,238, 0.8)" }}
                      >
                        <div className="text-white font-bold text-lg">
                          {category?.name}
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            {categoryDetailse?.products &&
              categoryDetailse?.products?.map((product) => {
                return (
                  <Link to={`/product/${product.id}`}>
                    <div
                      className={`relative md:w-[16vw] md:h-[16vw] xs:w-[30v] xs:h-[30vh] group overflow-hidden rounded-lg
                        hover:scale-110 transition duration-300 ${style.background}`}
                    >
                      <img
                        src={product?.coverImage?.url}
                        className="absolute h-full w-full object-contain"
                        alt={product?.name}
                      />
                      <div
                        className="absolute h-full w-full invisible group-hover:visible flex justify-center items-center transition"
                        style={{ background: "rgba(0,173,238, 0.8)" }}
                      >
                        <div className="text-white font-bold max-w-min text-lg block m-auto">
                          {product?.title}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </>
      )}

    </div>
  );
};

export default CategoryDetailsPage;
