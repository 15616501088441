import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Rotate } from "react-reveal";
import { Link } from "react-router-dom";
import Loader from "../../components/loading";

const HomePage = () => {
  const [categories, setCategories,] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const getCategories = async () => {
    setLoadingCategories(true);
    await axios({
      method: "post",
      url: "https://api.buildings-experts.com/api/v1/product-categories/index",
      headers: {},
      data: {
        staticFilters: [
          {
            name: "parentId",
            opretion: "=",
            value: null,
          },
        ],
      },
    })
      .then((data) => {
        setCategories(data.data?.data);
      })
      .finally((_) => {
        setLoadingCategories(false);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);
  


  return (
    <>
      {loadingCategories ? (
        <div className="w-screen bg-gray-200 h-screen opacity-80">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Rotate top left>
              <Loader />
            </Rotate>
          </div>
        </div>
      ) : (
        <>
          <div></div>
          <div className="flex justify-center items-center flex-wrap xs:flex-col md:flex-row gap-2 w-full mt-10 px-2">
            {categories.map((a, index) => {
               if (a.name === "Highlights")
               {
                return null;
              }
              return (
                <Fragment key={index}>
                  <Link
                  style={{
                    backgroundImage:`url(${a?.image?.url})` , width:'full' ,  height:'full',
                    backgroundPosition: 'center right', backgroundSize:'cover'
                  }}
                    className="w-full h-full
                      hover:grow hover:text-3xl rounded-2xl font-bold text-md md:hover:text-2xl text-white overflow-hidden relative transition-all flex items-center justify-center duration-700 animate-ease-in-out md:h-[75vh] xs:h-[20vh] md:w-1/6 xs:w-full  hover:cursor-pointer group"
                    to={`category/${a?.id}`}
                  >
                    <div
                      style={{ backgroundColor: 'rgb(0 149 217 / 81%)'  , backgroundBlendMode:'multiply'}}
                      className="absolute rounded-md w-full h-full opacity-80 group-hover:opacity-20 transition-opacity duration-150 flex items-center justify-center"
                    >
                    </div>
                    <div className="flex items-center justify-center text-3xl font-medium duration-200 px-10 hover:-translate-y-1 transition">
                      <Rotate duration={2000} left>
                        {a?.name}
                      </Rotate>
                    </div>
                  </Link>
                </Fragment>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default HomePage;
