import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useParams } from "react-router";
//
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfReader = () => {
  const { name } = useParams();

  // const divRef = useRef();
  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // const handleNextPage = () => {
  //   if (pageNumber < numPages) {
  //     setPageNumber((prevState) => prevState + 1);
  //   }
  // };
  // const handlePreviousPage = () => {
  //   if (pageNumber > 1) {
  //     setPageNumber((prevState) => prevState - 1);
  //   }
  // };

  useEffect(() => {
    console.log(name);
  }, [name]);
  return (
    <div>
      {name && (
        <Document
          file={`/certificate/${name}.pdf`}
          className={"PDFDocument"}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              width={1000}
              className={"PDFPage react-pdf__Page pdf-page"}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      )}
    </div>

    /* <div ref={divRef}>
        <div className="flex justify-center">
          {pageNumber} / {numPages}{" "}
        </div>
      
        <div className="flex justify-center">
            {pageNumber > 1 && (
              <button onClick={handlePreviousPage}> Previous</button>
            )}
            {pageNumber < numPages && (
              <button onClick={handleNextPage}> Next</button>
            )}
          </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Document
            className={"PDFDocument"}
            file={`/certificate/${name}.pdf`}
            onLoadError={() => alert("error")}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              className={"PDFPage PDFPageOne"}
              pageNumber={pageNumber}
              // width={divRef.current?.clientWidth * 0.66}
            />
          </Document>
        </div>
        <div>
         
        </div>

    </div>
     */
  );
};

export default PdfReader;
{
  /* <div id="">
{name && (
  <Document file={`/certificate/${name}.pdf`}   className={"PDFDocument"} onLoadSuccess={onDocumentLoadSuccess}>
    {Array.from(new Array(numPages), (el, index) => (
      <Page 
      className={"PDFPage PDFPageOne"}
      key={`page_${index + 1}`} pageNumber={index + 1} />
    ))}
  </Document>
)}
</div> */
}
